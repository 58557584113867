import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="blog-details__main">
        <div className="blog-details__content px-5">
          <h3 className="text-center">PRIVACY POLICY</h3>
          <p>
            Oppam ensures a steady commitment to User privacy with regard to the
            protection of Your personal information that you may share across
            this Platform/ Application. For the purpose of registration and to
            provide Services We may collect and, in some circumstances, disclose
            information about you with your permission. To ensure better
            protection of Your privacy, We provide this notice explaining Our
            information collection and disclosure policies.
          </p>
          <h4>DATA COLLECTED</h4>
          <p>
            We are committed to respecting Your online privacy. We further
            recognize Your need for appropriate protection and management of any
            Personal Information You share with us. We may collect the following
            information:
            <ul>
              <li>
                Personal data including but not limited to Name, Phone Number,
                Email ID, Address, City, Gender, Age, Payment Details as per
                Payment Gateway Rules
              </li>
              <li>
                Information collected through permission derived by the Platform
                for Location access, and storage permissions etc.
              </li>
              <li>
                Tracking Information such as IP address, Device ID, URL visited,
                browser information, and other interactions with the Platform
              </li>
              <li>Details of Platform usage for analytics</li>
            </ul>
          </p>
          <h4>Why Data Collection?</h4>
          <ul>
            <li>To enable the provision of services opted for by you</li>
            <li>To enable the viewing of content in your interest</li>
            <li>
              To communicate the necessary account and service-related
              information from time to time
            </li>
            <li>
              To allow you to receive quality customer care services and data
              Collection
            </li>
            <li>To comply with applicable laws, rules and regulations</li>
            <li>To enable Supporters to engage in a sound manner.</li>
          </ul>
          <p>
            We may use Your contact information internally to direct its efforts
            for service improvement but shall immediately delete all such
            information upon withdrawal of your consent for the same through the
            ‘unsubscribe’ button or through an email to be sent to
            “info@cigna.org.in”. To the extent possible, we provide You with the
            option of not divulging any specific information that you wish for
            us not to collect, store or use. You may also choose not to use a
            particular service or feature on the Platform and opt-out of any
            non-essential communications from the Platform.
          </p>
          <h4>Use of your data</h4>
          <p>
            The information provided by you shall be used to provide and improve
            the service for you and all users.
            <ul>
              <li>To provide you with services on your request.</li>
              <li>For maintaining an internal record.</li>
              <li>For enhancing the Services provided.</li>
              <li>
                For maintaining record under the legal and statutory provisions.
              </li>
            </ul>
          </p>
          <p>
            We will not sell, license or trade Your personal information. We
            will not share your personal information with others unless they are
            acting under our instructions or we are required to do so by law.
            <br /> Information collected via Our server logs includes users' IP
            addresses and the pages visited; this will be used to manage the web
            system and troubleshoot problems. We also use third-party analytics,
            tracking, optimization and targeting tools to understand how users
            engage with our Platform so that we can improve it and cater
            personalized content/ads according to their preferences.
          </p>
          <h4>How data is collected?</h4>
          <p>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected from
            You which shall not be mandatory to be disclosed by You.
            <br /> We will collect and use your personal information solely to
            fulfil those purposes specified by us, within the scope of the
            consent of the individual concerned or as required by law.
            <br /> We will only retain personal information as long as necessary
            for the fulfilment of those purposes. We will collect personal
            information by lawful and fair means and with Your knowledge and
            consent.
            <br /> Personal data should be relevant to the purposes for which it
            is to be used, and, to the extent necessary for those purposes,
            should be accurate, complete, and up-to-date.
          </p>
          <h4>EXTERNAL LINKS ON THE PLATFORM</h4>
          <p>
            The Platform may include advertisements, hyperlinks to other
            Platforms or resources. We have no control over any other Platform
            or resources or contents available on these other Platforms, which
            are provided by companies or persons other than Oppam. You
            acknowledge and agree that we are not responsible for the
            availability of any such external sites or resources, and do not
            endorse any advertising, services/services or other materials on or
            available from such Platform or resources. You acknowledge and agree
            that We are not liable for any loss or damage which may be incurred
            by you as a result of the availability of those external sites or
            resources, or as a result of any reliance placed by you on the
            completeness, accuracy or existence of any advertising, services or
            other materials on, or available from, such Platforms. These
            external third-party Platforms and resource providers may have their
            privacy policies governing the collection, storage, retention and
            disclosure of Your Personal Information that You may be subject to.
            We recommend that you enter their Platform and review its privacy
            policy.
          </p>
          <h4>PERMISSION AND ACCESS FROM THE DEVICE</h4>
          <p>
            The Application requires permission to access the following
            features:
            <ul>
              <li>Files & Media</li>
              <li>Location</li>
              <li>Contact</li>
              <li>Notifications</li>
              <li>Phone</li>
              <li>Pop up window</li>
            </ul>
          </p>
          <h4>COOKIES</h4>
          <p>
            A cookie is a small file of letters and numbers that we store on
            your browser or the hard drive of your computer if you agree. By
            continuing to browse the site, you are agreeing to our use of
            cookies. Cookies contain information that is transferred to your
            computer’s hard drive. You can set your browser to refuse all or
            some browser cookies, or to alert you when Platforms set or access
            cookies. If you disable or refuse cookies, please note that some
            parts of this Platform may become inaccessible or not function
            properly.
          </p>
          <h4>YOUR RIGHTS</h4>
          <p>
            Unless subject to an exemption, you have the following rights
            concerning your data:
            <ul>
              <li>
                The right to request a copy of your data which we hold about
                you.
              </li>
              <li>
                The right to request for any correction to any personal data if
                it is found to be inaccurate or out of date.
              </li>
              <li>
                The right to withdraw Your consent to the processing at any
                time.
              </li>
              <li>The right to object to the processing of personal data.</li>
              <li>The right to complain about a supervisory authority.</li>
              <li>
                The right to obtain information as to whether personal data are
                transferred to a third country or an international organization.
              </li>
            </ul>
          </p>
          <p>
            Where you hold an account with any of our services, you are entitled
            to a copy of all personal data which we hold concerning you. You are
            also entitled to request that we restrict how we use your data in
            your account when you log in.
          </p>
          <h4>COMPLIANCES</h4>
          <ul>
            <li>
              This legal agreement is an electronic record in terms of the
              Indian Information Technology Act, 2000 and rules there under as
              applicable and the amended provisions about electronic records in
              various statutes as amended by the Indian Information Technology
              Act, 2000. This electronic record is generated by a computer
              system and does not require any physical or digital signatures.
            </li>
            <li>
              This legal document is published in accordance with the provisions
              of Rule 3 (1) of the Indian Information Technology (Intermediaries
              guidelines) Rules, 2011 and Rule 4 of the Information Technology
              (Reasonable security practices and procedures and sensitive
              personal data or information) Rules, 2011 of Information
              Technology Act, 2000 amended through Information Technology
              Amendment Act, 2008 that require publishing the Terms of Use and
              practices for access and usage of any functional Platform.
            </li>
          </ul>
          <h4>GENERAL</h4>
          <ul>
            <li>
              The User may avail Services of the Platform/Application without
              revealing any data/ information which however may be disclosed by
              the User if necessary or otherwise required by law.
            </li>
            <li>
              The User may not disclose any personal/ sensitive information to
              the Supporters and Oppam shall not be responsible for the conduct/
              behaviour between the User and the Supporters.
            </li>
            <li>
              Oppam may receive personal data of the User in the instance of
              payments for Services and no such data shall be retained by Oppam
            </li>
          </ul>
          <h4>AMENDMENT</h4>
          <p>
            Our Privacy Policy may change from time to time. The most current
            version of the policy will govern our use of your information and
            will always be on the Platform. Any amendments to this Policy shall
            be deemed as accepted by the User on their continued use of the
            Platform.
          </p>
          <h4>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</h4>
          <p>
            To withdraw your consent, or to request the download or delete your
            data with us for any or all our services at any time, please email
            to “info@cigna.org.in”.
          </p>
          <h4>CONTACT US</h4>
          <p>
            If you have any questions or concerns regarding this privacy policy,
            you should contact us by sending an e-mail to “info@cigna.org.in”.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
